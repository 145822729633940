import { Pagination } from './Pagination'

export class CourierPickupDetail {
  public courierId: number
  public courierName: string
  public courierTypeValid: string
  public origin: string
  public partnerName: string
  public partnerType: string
  public phoneNumber: string
  public vehiclePlate: string
  public vehicleType: string

  constructor(
    courierId: number,
    courierName: string,
    courierTypeValid: string,
    origin: string,
    partnerName: string,
    partnerType: string,
    phoneNumber: string,
    vehiclePlate: string,
    vehicleType: string
  ) {
    this.courierId = courierId
    this.courierName = courierName
    this.courierTypeValid = courierTypeValid
    this.origin = origin
    this.partnerName = partnerName
    this.partnerType = partnerType
    this.phoneNumber = phoneNumber
    this.vehiclePlate = vehiclePlate
    this.vehicleType = vehicleType
  }
}

export class PickupAccount {
  public accountId?: number
  public accountRole?: string

  constructor(accountId?: number, accountRole?: string) {
    this.accountId = accountId
    this.accountRole = accountRole
  }
}

export class PickupInfo {
  public fullname: string
  public address: string
  public phoneNumber: string
  public latitude: number
  public longitude: number
  public note: string
  public country: string
  public province: string
  public district: string
  public subDistrict: string
  public village: string

  constructor(
    fullname: string,
    address: string,
    phoneNumber: string,
    latitude: number,
    longitude: number,
    note: string,
    country: string,
    province: string,
    district: string,
    subDistrict: string,
    village: string
  ) {
    this.fullname = fullname
    this.address = address
    this.phoneNumber = phoneNumber
    this.latitude = latitude
    this.longitude = longitude
    this.note = note
    this.country = country
    this.province = province
    this.district = district
    this.subDistrict = subDistrict
    this.village = village
  }
}

export class PickupHistory {
  public historyId: number
  public historyType: string
  public statusId: string
  public status: string
  public location: string
  public city: string
  public actorId: number
  public actorRole: string
  public actorName: string
  public reasonId: string
  public reason: string
  public relationId: string
  public relation: string
  public personName: string
  public note: string
  public meta: string | null
  public createdAt: string
  public totalNfd?: number

  constructor(
    historyId: number,
    historyType: string,
    statusId: string,
    status: string,
    location: string,
    city: string,
    actorId: number,
    actorRole: string,
    actorName: string,
    reasonId: string,
    reason: string,
    relationId: string,
    relation: string,
    personName: string,
    note: string,
    meta: string | null,
    createdAt: string,
    totalNfd?: number
  ) {
    this.historyId = historyId
    this.historyType = historyType
    this.statusId = statusId
    this.status = status
    this.location = location
    this.city = city
    this.actorId = actorId
    this.actorRole = actorRole
    this.actorName = actorName
    this.reasonId = reasonId
    this.reason = reason
    this.relationId = relationId
    this.relation = relation
    this.personName = personName
    this.note = note
    this.meta = meta
    this.createdAt = createdAt
    this.totalNfd = totalNfd
  }
}

export class Pickup {
  public shipmentId: string
  public groupId: string
  public shipmentType: string
  public account: PickupAccount
  public pickup: PickupInfo | null
  public isOnepack: boolean
  public isDedicated: boolean
  public quantity: number
  public createdAt: string
  public expiredAt: string
  public history: PickupHistory
  public flag: string
  public doOriginPackageId: string
  public bookingId: string
  public courierPickupDetail?: CourierPickupDetail
  public productType?: string
  public isPriority?: boolean
  public pickupBoosterAmount?: number

  constructor(
    shipmentId: string,
    groupId: string,
    shipmentType: string,
    account: PickupAccount,
    pickup: PickupInfo | null,
    isOnepack: boolean,
    isDedicated: boolean,
    quantity: number,
    createdAt: string,
    expiredAt: string,
    history: PickupHistory,
    flag: string,
    doOriginPackageId: string,
    bookingId: string,
    courierPickupDetail?: CourierPickupDetail,
    productType?: string,
    isPriority?: boolean,
    pickupBoosterAmount?: number
  ) {
    this.shipmentId = shipmentId
    this.groupId = groupId
    this.shipmentType = shipmentType
    this.account = account
    this.pickup = pickup
    this.isOnepack = isOnepack
    this.isDedicated = isDedicated
    this.quantity = quantity
    this.createdAt = createdAt
    this.expiredAt = expiredAt
    this.history = history
    this.flag = flag
    this.doOriginPackageId = doOriginPackageId
    this.bookingId = bookingId
    this.courierPickupDetail = courierPickupDetail
    this.productType = productType
    this.isPriority = isPriority
    this.pickupBoosterAmount = pickupBoosterAmount
  }
}

export class Pickups {
  public pagination: Pagination
  public data: Pickup[]

  constructor(pagination: Pagination, data: Pickup[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class PickupDetail {
  public shipmentId?: string
  public shipmentType?: string
  public groupId?: string
  public account?: PickupAccount
  public pickup?: PickupInfo
  public isOnepack?: boolean
  public quantity?: number
  public createdAt?: string
  public expiredAt?: string
  public invoiceId?: string
  public bookingId?: string
  public histories?: PickupHistory[]
  public flag?: string
  public lastCancelCourier?: string
  public shipmentGroupIds?: string[]
  public productType?: string[]
  public weight?: number
  public clientId?: string
  public clientName?: string
  public vehicleExpectation?: string
  public pickupDateExpectation?: string
  public pickupTimeExpectation?: string
  public estimateKoli?: number
  public estimasiTonase?: number
  public totalNfd?: number
  public isPriority?: boolean
  public pickupBoosterAmount?: number

  constructor(
    shipmentId?: string,
    groupId?: string,
    shipmentType?: string,
    account?: PickupAccount,
    pickup?: PickupInfo,
    isOnepack?: boolean,
    quantity?: number,
    createdAt?: string,
    expiredAt?: string,
    invoiceId?: string,
    bookingId?: string,
    histories?: PickupHistory[],
    flag?: string,
    lastCancelCourier?: string,
    shipmentGroupIds?: string[],
    productType?: string[],
    weight?: number,
    clientId?: string,
    clientName?: string,
    vehicleExpectation?: string,
    pickupDateExpectation?: string,
    pickupTimeExpectation?: string,
    estimateKoli?: number,
    estimasiTonase?: number,
    totalNfd?: number,
    isPriority?: boolean,
    pickupBoosterAmount?: number

  ) {
    this.shipmentId = shipmentId
    this.groupId = groupId
    this.shipmentType = shipmentType
    this.account = account
    this.pickup = pickup
    this.isOnepack = isOnepack
    this.quantity = quantity
    this.createdAt = createdAt
    this.expiredAt = expiredAt
    this.invoiceId = invoiceId
    this.bookingId = bookingId
    this.histories = histories
    this.flag = flag
    this.lastCancelCourier = lastCancelCourier
    this.shipmentGroupIds = shipmentGroupIds
    this.productType = productType
    this.weight = weight
    this.clientId = clientId
    this.clientName = clientName
    this.vehicleExpectation = vehicleExpectation
    this.pickupDateExpectation = pickupDateExpectation
    this.pickupTimeExpectation = pickupTimeExpectation
    this.estimateKoli = estimateKoli
    this.estimasiTonase = estimasiTonase
    this.totalNfd = totalNfd
    this.isPriority = isPriority
    this.pickupBoosterAmount = pickupBoosterAmount
  }
}

export class PickupForceDetail {
  public actorName?: string
  public bookingId?: string
  public courierId?: number
  public customerRole?: string
  public isDedicated?: boolean
  public receiverAddress?: string
  public receiverContact?: string
  public receiverName?: string
  public shipmentId?: string
  public taskId?: number

  constructor(
    actorName?: string,
    bookingId?: string,
    courierId?: number,
    customerRole?: string,
    isDedicated?: boolean,
    receiverAddress?: string,
    receiverContact?: string,
    receiverName?: string,
    shipmentId?: string,
    taskId?: number
  ) {
    this.actorName = actorName
    this.bookingId = bookingId
    this.courierId = courierId
    this.customerRole = customerRole
    this.isDedicated = isDedicated
    this.receiverAddress = receiverAddress
    this.receiverContact = receiverContact
    this.receiverName = receiverName
    this.shipmentId = shipmentId
    this.taskId = taskId
  }
}

export class PickupDetailSnapshot {
  public snapshotId?: number
  public shipmentId?: string
  public couriers?: Couriers[]
  public createdAt?: string

  constructor(
    snapshotId?: number,
    shipmentId?: string,
    couriers?: Couriers[],
    createdAt?: string
  ) {
    this.snapshotId = snapshotId
    this.shipmentId = shipmentId
    this.couriers = couriers
    this.createdAt = createdAt
  }
}

export class Couriers {
  public courierId?: number
  public latitude?: number
  public longitude?: number
  public distance?: number
  public createdAt?: string

  constructor(
    courierId: number,
    latitude: number,
    longitude: number,
    distance: number,
    createdAt: string
  ) {
    this.courierId = courierId
    this.latitude = latitude
    this.longitude = longitude
    this.distance = distance
    this.createdAt = createdAt
  }
}

export class PickupSummary {
  public crrsrc?: number
  public crrnfd?: number
  public dox?: number
  public crrotw?: number
  public crrpic?: number
  public crrdon?: number
  public all?: number
  public crrque?: number

  constructor(
    crrsrc?: number,
    crrnfd?: number,
    dox?: number,
    crrotw?: number,
    crrpic?: number,
    crrdon?: number,
    all?: number,
    crrque?: number
  ) {
    this.crrsrc = crrsrc
    this.crrnfd = crrnfd
    this.dox = dox
    this.crrotw = crrotw
    this.crrpic = crrpic
    this.crrdon = crrdon
    this.all = all
    this.crrque = crrque
  }
}

export class NearbyCourier {
  public courierId: number
  public courierName: string
  public courierPhone: string
  public courierPOS: string
  public onDuty: boolean

  constructor(
    courierId: number,
    courierName: string,
    courierPhone: string,
    courierPOS: string,
    onDuty: boolean
  ) {
    this.courierId = courierId
    this.courierName = courierName
    this.courierPhone = courierPhone
    this.courierPOS = courierPOS
    this.onDuty = onDuty
  }
}

export class PickupAdditionalTime {
  public orderId: string
  public additionalTime: number
  public courierId: number
  public courierName: string
  public startTimePickup: string
  public endTimePickup: string

  constructor(
    orderId: string,
    additionalTime: number,
    courierId: number,
    courierName: string,
    startTimePickup: string,
    endTimePickup: string
  ) {
    this.orderId = orderId
    this.additionalTime = additionalTime
    this.courierId = courierId
    this.courierName = courierName
    this.startTimePickup = startTimePickup
    this.endTimePickup = endTimePickup
  }
}

export class PickupAdditionalTimes {
  public pagination: Pagination
  public data: PickupAdditionalTime[]

  constructor(pagination: Pagination, data: PickupAdditionalTime[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class PickupCRROTW {
  public shipmentId: string
  public groupId: string
  public shipmentType: string
  public account: PickupAccount
  public pickup: PickupInfo | null
  public isOnepack: boolean
  public productType: string
  public quantity: number
  public createdAt: string
  public expiredAt: string
  public crrotwAt: string
  public crrsrcAt: string
  public lastUpdated: string
  public doOriginPackageId: string
  public bookingId: string
  public taskId: string
  public flag: string
  public isDedicated: boolean
  public isContacted: boolean
  public canContact: boolean
  public courierPickupDetail?: CourierPickupDetail
  public isPriority?: boolean
  public pickupBoosterAmount?: number

  constructor(
    shipmentId: string,
    groupId: string,
    shipmentType: string,
    account: PickupAccount,
    pickup: PickupInfo | null,
    isOnepack: boolean,
    productType: string,
    quantity: number,
    createdAt: string,
    expiredAt: string,
    crrotwAt: string,
    crrsrcAt: string,
    lastUpdated: string,
    doOriginPackageId: string,
    bookingId: string,
    taskId: string,
    flag: string,
    isDedicated: boolean,
    isContacted: boolean,
    canContact: boolean,
    courierPickupDetail?: CourierPickupDetail,
    isPriority?: boolean,
    pickupBoosterAmount?: number
  ) {
    this.shipmentId = shipmentId
    this.groupId = groupId
    this.shipmentType = shipmentType
    this.account = account
    this.pickup = pickup
    this.isOnepack = isOnepack
    this.productType = productType
    this.quantity = quantity
    this.createdAt = createdAt
    this.expiredAt = expiredAt
    this.crrotwAt = crrotwAt
    this.crrsrcAt = crrsrcAt
    this.lastUpdated = lastUpdated
    this.doOriginPackageId = doOriginPackageId
    this.bookingId = bookingId
    this.taskId = taskId
    this.flag = flag
    this.isDedicated = isDedicated
    this.isContacted = isContacted
    this.canContact = canContact
    this.courierPickupDetail = courierPickupDetail
    this.isPriority = isPriority
    this.pickupBoosterAmount = pickupBoosterAmount
  }
}

export class PickupCRROTWSummary {
  public moretwoten?: number
  public ninety?: number
  public oneeighty?: number
  public onefifty?: number
  public onetwenty?: number
  public sixty?: number
  public thirty?: number
  public twoten?: number

  constructor(
    moretwoten?: number,
    ninety?: number,
    oneeighty?: number,
    onefifty?: number,
    onetwenty?: number,
    sixty?: number,
    thirty?: number,
    twoten?: number
  ) {
    this.moretwoten = moretwoten
    this.ninety = ninety
    this.oneeighty = oneeighty
    this.onefifty = onefifty
    this.onetwenty = onetwenty
    this.sixty = sixty
    this.thirty = thirty
    this.twoten = twoten
  }
}

export class PickupCRROTWs {
  public pagination?: Pagination
  public data?: PickupCRROTW[]
  public summary?: PickupCRROTWSummary

  constructor(
    pagination?: Pagination,
    data?: PickupCRROTW[],
    summary?: PickupCRROTWSummary
  ) {
    this.pagination = pagination
    this.data = data
    this.summary = summary
  }
}

export class PickupCRRQUE {
  public shipmentId?: string
  public shipmentType?: string
  public customer?: PickupCRRQUECustomer
  public pos?: PickupCRRQUEPOS
  public pickup?: PickupCRRQUEPickup
  public isContacted?: boolean
  public quantity?: number
  public createdAt?: string
  public expiredAt?: string
  public crrqueAt?: string
  public crrsrcAt?: any
  public flag?: string
  public canContact?: boolean

  constructor(
    shipmentId?: string,
    shipmentType?: string,
    customer?: PickupCRRQUECustomer,
    pos?: PickupCRRQUEPOS,
    pickup?: PickupCRRQUEPickup,
    isContacted?: boolean,
    createdAt?: string,
    expiredAt?: string,
    crrqueAt?: string,
    crrsrcAt?: string,
    flag?: string,
    canContact?: boolean
  ) {
    this.shipmentId = shipmentId
    this.shipmentType = shipmentType
    this.customer = customer
    this.pos = pos
    this.pickup = pickup
    this.isContacted = isContacted
    this.createdAt = createdAt
    this.expiredAt = expiredAt
    this.crrqueAt = crrqueAt
    this.crrsrcAt = crrsrcAt
    this.flag = flag
    this.canContact = canContact
  }
}

export class PickupCRRQUEPOS {
  public name?: string
  public phone?: string
  public agentId?: number
  public agentCode?: string
  public address?: string

  constructor(
    name?: string,
    phone?: string,
    agentId?: number,
    agentCode?: string,
    address?: string
  ) {
    this.name = name
    this.phone = phone
    this.agentId = agentId
    this.agentCode = agentCode
    this.address = address
  }
}

export class PickupCRRQUECustomer {
  public name?: string
  public address?: string
  public phoneNumber?: string

  constructor(name?: string, address?: string, phoneNumber?: string) {
    this.name = name
    this.address = address
    this.phoneNumber = phoneNumber
  }
}

export class PickupCRRQUEPickup {
  public latitude?: number
  public longitude?: number

  constructor(latitude?: number, longitude?: number) {
    this.latitude = latitude
    this.longitude = longitude
  }
}

export class PickupCRRQUESummary extends PickupCRROTWSummary {
  constructor(
    moretwoten?: number,
    ninety?: number,
    oneeighty?: number,
    onefifty?: number,
    onetwenty?: number,
    sixty?: number,
    thirty?: number,
    twoten?: number
  ) {
    super(
      moretwoten,
      ninety,
      oneeighty,
      onefifty,
      onetwenty,
      sixty,
      thirty,
      twoten
    )
  }
}

export class PickupCRRQUEs {
  public data?: PickupCRRQUE[]
  public pagination?: Pagination
  public summary?: PickupCRRQUESummary

  constructor(
    data?: PickupCRRQUE[],
    pagination?: Pagination,
    summary?: PickupCRRQUESummary
  ) {
    this.data = data
    this.pagination = pagination
    this.summary = summary
  }
}

export class PickupCorporate {
  shipmentId = ''
  groupId = ''
  shipmentType = ''
  account = new PickupAccount(0, '')
  pickup = new PickupInfo('', '', '', 0, 0, '', '', '', '', '', '')
  isOnepack = false
  productType = ''
  quantity = 0
  weight = 0
  createdAt = ''
  expirateAt = ''
  history = new PickupHistory(
    0,
    '',
    '',
    '',
    '',
    '',
    0,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    null,
    ''
  )
  flag = ''
  doOriginPackageId = ''
  bookingId = ''
  taskId = ''
  isDedicated = false
  clientId = ''
  clientName = ''
  totalNfd = 0
  estimateKoli = 0
  estimasiTonasi = 0
  pickupDateExpectation = ''
  pickupTimeExpectation = ''
  vehicleExpectation = ''
  couerierPickupDetail?: CourierPickupDetail
  constructor(fields?: Partial<PickupCorporate>) {
    Object.assign(this, fields)
  }
}

export class PickupCorporates {
  data: PickupCorporate[] = []
  pagination: Pagination = new Pagination(1, 10, 0)

  constructor(fields?: Partial<PickupCorporates>) {
    Object.assign(this, fields)
  }
}

export class PickupCorporateSummary {
  one = 0;
  two = 0;
  three = 0;
  four = 0;
  five = 0;
  moreThanFive = 0;

  constructor(fields?: Partial<PickupCorporateSummary>) {
    Object.assign(this, fields);
  }
}
